import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AlertTitle from '@material-ui/lab/AlertTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment-timezone'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  UPDATE_ORDER,
  GET_ORDERS,
  CREATE_ORDER,
  REMOVE_ORDER,
  GET_bookingdetails,
  GET_ORDER,
  RECEIVED_ORDER,
} from '../graphql/order'
import {
  GET_BOOKINGDETAILS,
  RECEIVED_BOOKING,
  CANCEL_BOOKING,
  GET_BOOKINGS,
} from '../graphql/booking'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import AutocompleteField from '../common/autocompleteField'
import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'
import { useSnackbar } from 'notistack'
import LoginDialog from './loginDialog'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

// START
export default function BookingDetailsDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const token = props.data && props.data.Token

  const {
    loading: loadingBookingDetails,
    refetch,
    data: { bookingdetails } = { bookingdetails: {} },
  } = useQuery(GET_BOOKINGDETAILS, {
    variables: { Token: token },
    skip: !token,
  })

  const [staff, setStaff] = useState('')
  const [staffName, setStaffName] = useState('')

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  /* 
  const {
    loading: loadingOrder,
    data: { order } = { order: {} },
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { Token: token },
    skip: !token,
  })
 */
  const updateCache = (cache, { data }) => {
    if (data.receivedbooking.Error) {
      enqueueSnackbar(data.receivedbooking.Error, {
        variant: 'error',
      })
    } else {
      refetch()
    }
  }

  /*

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.createorder
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [latest, ...old.orders] },
    })
  }

  const [createOrder] = useMutation(CREATE_ORDER, { update: addCache }) */
  const [receivedBooking] = useMutation(RECEIVED_BOOKING, {
    update: updateCache,
  })

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [staffID, setStaffID] = useState()
  const [openLogin, setOpenLogin] = useState(false)
  const [openLoginDelete, setOpenLoginDelete] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BOOKINGS,
    })
    if (data.cancelbooking.Error) {
      enqueueSnackbar(data.cancelbooking.Error, {
        variant: 'error',
      })
    } else {
      const latest = old.bookings.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_BOOKINGS,
        data: { bookings: [...latest] },
      })
    }
  }

  const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    /* if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } */
  }

  const handleClickCollected = (password) => {
    receivedBooking({
      variables: {
        Token: token,
        SalesPerson: staff,
        Password: password,
      },
    })
    setConfirm(false)
    props.setOpen(false)
  }

  const handleClickDelete = (password) => {
    cancelBooking({
      variables: {
        id: props.data.id,
        SalesPerson: staff,
        Password: password,
      },
    })
    //setConfirmDelete(false)
    props.setOpen(false)
  }

  /*  useEffect(() => {
    if (bookingdetails.length == 0) return

    const totalAmount = bookingdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [bookingdetails]) */

  /* useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount]) */

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      scroll="body"
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      disableBackdropClick={false}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="manifesting-dialog"
    >
      <LoginDialog
        staffID={staffID}
        setOpen={setOpenLogin}
        open={openLogin}
        action={handleClickCollected}
        title={`Password for ${staff}`}
        dialogTitle={`Password`}
      />

      <LoginDialog
        staffID={staffID}
        setOpen={setOpenLoginDelete}
        open={openLoginDelete}
        action={handleClickDelete}
        title={`Password for ${staff}`}
        dialogTitle={`Password`}
      />

      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirmDelete}
        setConfirm={setConfirmDelete}
        message="Continue cancel Booking?"
        okButton="Yes"
        title="Continue cancel"
      />
      <React.Fragment>
        <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
          Booking Details
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              props.setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.root}>
            <ConfirmationDialog
              action={handleClickCollected}
              confirm={confirm}
              setConfirm={setConfirm}
              message="Confirm check in?"
              okButton="Yes"
              title="Check In"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <pre>{JSON.stringify(bookingdetails, 0, 4)}</pre> */}
              <Grid container spacing={3}>
                <Grid item md={4} xs={6}>
                  <TextFieldDisplay
                    label="Booking Branch"
                    value={bookingdetails.BranchName}
                  />
                </Grid>
                <Grid item md={5} xs={6}>
                  <TextFieldDisplay
                    label="Booking Date"
                    value={bookingdetails.BookFrom}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextFieldDisplay
                    label="Total Person"
                    value={bookingdetails.TotalPerson}
                  />
                </Grid>
                {/* 
          <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
            <TextFieldDisplay
              label="Order Total"
              value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
            />
          </Grid> */}
              </Grid>
              <Divider classes={{ root: classes.dividerroot }} />
              <br />
              <Grid container spacing={3}>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  <TextFieldDisplay
                    label="Name"
                    value={
                      bookingdetails.FirstName && bookingdetails.LastName
                        ? `${bookingdetails.FirstName} ${bookingdetails.LastName}`
                        : bookingdetails.Username
                    }
                  />
                </Grid>
                <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                  {/* <TextFieldDisplay
                    label="Booking Pass"
                    value={bookingdetails.ServiceTimes}
                  />  */}
                  <TextFieldDisplay
                    label="Phone"
                    value={
                      bookingdetails.CreatedBy == 'walkin'
                        ? '-'
                        : bookingdetails.CreatedBy
                    }
                  />
                </Grid>
              </Grid>
            </form>
            {!bookingdetails.ReceivedBy && (
              <div>
                <AutocompleteField
                  name="buyer"
                  label="Assign Customer Service"
                  value={staffName}
                  options={officestaffs.map((item) =>
                    item.FirstName && item.LastName
                      ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                      : item.Username
                  )}
                  onChange={(e, value) => {
                    const buyerValue = officestaffs.find(
                      (item) =>
                        (item.FirstName && item.LastName
                          ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                          : item.Username) === value
                    )
                    if (buyerValue) {
                      setStaffID(buyerValue.UserID)
                      setStaff(buyerValue.Username)
                      setStaffName(
                        buyerValue.FirstName && buyerValue.LastName
                          ? `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                          : buyerValue.Username
                      )
                    }
                  }}
                />
                <br />
              </div>
            )}
            {bookingdetails.ReceivedBy && (
              <Alert severity="success">
                <AlertTitle>Completed</AlertTitle>
                Member checked in. Customer Service assigned to{' '}
                {bookingdetails.ReceivedBy}
                {'.'}
              </Alert>
            )}
          </div>
          <div style={{ paddingBottom: 0, paddingTop: 15 }}>
            {props.data && !props.data.ReceivedBy && (
              <React.Fragment>
                <Button
                  disabled={!staffID}
                  variant="contained"
                  disableElevation
                  color="primary"
                  onClick={() => {
                    setOpenLoginDelete(true)
                  }}
                >
                  <DeleteIcon /> Cancel Booking
                </Button>
                <Button
                  disabled={!staffID}
                  variant="contained"
                  disableElevation
                  style={{ marginLeft: 5 }}
                  color="primary"
                  onClick={() => {
                    setOpenLogin(true)
                  }}
                >
                  Check In Booking
                </Button>
                <br />
                <br />
              </React.Fragment>
            )}
          </div>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  )
}
