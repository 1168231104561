import { gql } from 'apollo-boost'

export const GET_BANKS = gql`
  {
    banks {
      id
      BankID
      BankName
    }
  }
`
