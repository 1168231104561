import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Slider } from '@material-ui/core'
import axios from 'axios'
import getCroppedImg from '../common/cropImage'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import Cropper from 'react-easy-crop'
import ImgDialog from './imgDialog'

// const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT
const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  dropZone: {
    height: 400,
    background: theme.palette.primary.paper,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    paddingTop: 10,
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    paddingRight: 30,
  },
  sliderLabel: {
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}))

// START
export default function ProductResizeDialog(props) {
  const classes = useStyles()
  const [file, setFile] = useState()
  const [disable, setDisable] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  /* const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )*/

  const [dbhost, setDbhost] = useState('spa2')

  const [imageFile, setImageFile] = useState()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  useEffect(() => {
    if (props.resizeFileName)
      setImageFile(
        `${restApi}/media/${dbhost}/uploads/invoice/${props.resizeFileName}`
      )
  }, [props])

  const handleUpload = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageFile,
        croppedAreaPixels,
        rotation
      )
      //console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)

      if (!croppedImage) return
      const formData = new FormData()
      //formData.append('uploads[]', file)
      formData.append('uploads[]', croppedImage, 'product.jpg')
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      //formData.append('uploads[]', file.file[0], file.file[0]['name'])

      //formData.append("uploads[]", files[0], files[0]['name']);
      axios
        .put(restApi + `/upload/send/${props.data.id}/1`, formData, config)
        .then((response) => {
          props.setOpen(false)
          props.refetch()
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  /* const onChange = (e) => {
    console.log(e[0])
    const reader = new FileReader()
    reader.addEventListener('load', () => setFile(reader.result), false)
    reader.readAsDataURL(e[0])
  } */

  /* const onChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setFile(reader.result), false)
      reader.readAsDataURL(e.target.files[0])
    }
  } */

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="manifesting-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
            Product Image
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <React.Fragment>
              <div className={classes.cropContainer}>
                {props.resizeFileName && (
                  <Cropper
                    image={imageFile}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 4}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                )}
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Zoom
                  </Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    classes={{ container: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Rotation
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    classes={{ container: classes.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
              </div>
            </React.Fragment>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                handleUpload()
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
