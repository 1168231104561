import React from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  primary: { fontSize: '1.5rem', lineHeight: 1.5 },
  secondary: {
    fontSize: '0.8rem',
    lineHeight: 1,
    color: theme.palette.grey[500],
  },
}))

export default function TabTitle(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.primary}>{props.primary}</div>
        <div className={classes.secondary}>{props.secondary}</div>
      </div>
    </React.Fragment>
  )
}
