import { gql } from 'apollo-boost'

export const ADD_AWB = gql`
  mutation CreateAwb(
    $AWBNo: String!
    $ETA: Date
    $ETD: Date
    $AirlineID: String
    $AgentID: String
    $FlightNo: String
    $POL: String
    $POD: String
    $Express: String
    $Pieces: String
    $Weight: String
    $Remark: String
    $JobNo: String
    $ShipperCode: String
    $ConsigneeCode: String
  ) {
    createAwb(
      AWBNo: $AWBNo
      ETA: $ETA
      ETD: $ETD
      AirlineID: $AirlineID
      AgentID: $AgentID
      FlightNo: $FlightNo
      POL: $POL
      POD: $POD
      Express: $Express
      Pieces: $Pieces
      Weight: $Weight
      Remark: $Remark
      JobNo: $JobNo
      ShipperCode: $ShipperCode
      ConsigneeCode: $ConsigneeCode
    ) {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      Express
      Pieces
      Weight
      Remark
      JobNo
      AgentName
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
    }
  }
`

export const UPDATE_AWB = gql`
  mutation UpdateAwb(
    $id: ID!
    $AWBNo: String!
    $ETA: Date
    $ETD: Date
    $AirlineID: String
    $AgentID: String
    $FlightNo: String
    $POL: String
    $POD: String
    $Express: String
    $Pieces: String
    $Weight: String
    $Remark: String
    $JobNo: String
    $ShipperCode: String
    $ConsigneeCode: String
  ) {
    updateAwb(
      id: $id
      AWBNo: $AWBNo
      ETA: $ETA
      ETD: $ETD
      AirlineID: $AirlineID
      AgentID: $AgentID
      FlightNo: $FlightNo
      POL: $POL
      POD: $POD
      Express: $Express
      Pieces: $Pieces
      Weight: $Weight
      Remark: $Remark
      JobNo: $JobNo
      ShipperCode: $ShipperCode
      ConsigneeCode: $ConsigneeCode
    ) {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      Express
      Pieces
      Weight
      Remark
      JobNo
      AgentName
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
    }
  }
`

export const GET_MANIFESTINGS = gql`
  query Manifesting {
    manifestings {
      id
      AWBNo
      AgentName
      FlightNo
      ModifiedBy
      LastModified
      AirlineID
      Weight
      Pieces
      State
      ETD
      ETA
      POL
      POD
      Express
      Pieces
      Weight
      Remark
      JobNo
      MAWBNo
      ColoaderName
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
    }
  }
`

export const SEARCH_AWB = gql`
  query SearchAwb($AWBNo: String!) {
    searchawb(AWBNo: $AWBNo) {
      id
      AWBNo
    }
  }
`

export const DELETE_AWB = gql`
  mutation DeleteAwb($id: ID!) {
    deleteAwb(id: $id)
  }
`

export const GET_AWB_NOTLOCKED = gql`
  {
    awbsnotlocked {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      State
      AgentName
      MAWBNo
      ColoaderName
      Express
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
    }
  }
`

export const GET_AWB = gql`
  {
    awbs {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      Express
      Pieces
      Weight
      Remark
      JobNo
      AgentName
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
    }
  }
`

export const GET_AWB_SINGLE = gql`
  query Awb($id: ID!) {
    awb(id: $id) {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      AgentName
      ModifiedBy
      LastModified
      InvoiceDate
      InvoiceNo
      BillDate
      Express
      Pieces
      Weight
      Remark
      JobNo
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
      Consignments {
        ConsignmentNo
        Pieces
        Weight
        Destination
      }
    }
  }
`

export const SEARCH_AWB_SINGLE = gql`
  query SearchAwb($AWBNo: String!) {
    searchawb(AWBNo: $AWBNo) {
      id
      AWBNo
      ETD
      ETA
      POL
      POD
      AirlineID
      FlightNo
      AgentID
      Remark
      Pieces
      Weight
      Status
      LockDate
      LockBy
      BillComplete
      BillType
      AgentName
      ModifiedBy
      LastModified
      InvoiceDate
      InvoiceNo
      BillDate
      BillBy
      Express
      Pieces
      Weight
      Remark
      JobNo
      MAWBNo
      ShipperName
      ShipperCode
      ConsigneeCode
      ConsigneeName
      Consignments {
        ConsignmentNo
        Pieces
        Weight
        Destination
      }
    }
  }
`
