import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import BookingDisplay from './orderDisplay'
import BookingDialog from './orderDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import PunchcardScanDialog from './punchcardScanDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

export default function PunchcardScan() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  /* const {
    loading,
    error,
    data: { orders } = { orders: [] },
    refetch: refetchOrders,
  } = useQuery(GET_ORDERS) */

  return (
    <div className={classes.root}>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Order?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <PunchcardScanDialog setOpen={setOpen} open={open} />

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(true)
        }}
      >
        Attendance QR Scanner
      </Button>
      {/* <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchOrders()
        }}
      >
        Refresh
      </Button> */}
      {/* <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <BookingDialog
            setOpen={setOpen}
            setSelected={setSelected}
            setOrder={setOrder}
            data={order}
            open={open}
            refetchOrders={refetchOrders}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setOrder}
            tableData={orders}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid> */}
      {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <BookingDisplay data={order} />
            </div>
          )}
        </Grid> */}
      {/* </Grid> */}
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
