import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import StockDialog from './stockDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { GET_STOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  /* {
    id: 'IDSKU',
    label: 'SKU',
  }, */
  {
    id: 'ProductName',
    label: 'Product Name',
    width: 500,
  },
  {
    id: 'Title',
    label: 'Title',
  },
  {
    id: 'Qty',
    label: 'Qty',
  },
  {
    id: 'CreatedBy',
    logdate: 'CreatedOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Date',
    width: 300,
  } /* 
  {
    id: 'Quantity',
    label: 'Quantity',
  },
  {
    id: 'UnitPrice',
    label: 'UnitPrice',
  },
  {
    id: 'UnitsInStock',
    label: 'UnitsInStock',
  },
  {
    id: 'UnitsInOrder',
    label: 'UnitsInOrder',
  }, */,
  ,
  ,
]

export default function Stock() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [stock, setStock] = useState()

  const {
    data: { stocks } = { stocks: [] },
    refetch: refetchStocks,
  } = useQuery(GET_STOCKS, {
    variables: {
      Movement: 'In',
    },
  })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== selected[0])
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
      setSelected([])
      setStock()
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  })

  const handleClickOpen = () => {
    setStock()
    setSelected([])
    setOpen(true)
  }

  const handleClickDelete = () => {
    removeOrder({
      variables: {
        id: selected[0],
      },
    })
    setConfirm(false)
    setSelected([])
    setStock()
  }

  /*
  //if (loading) return <Loading />
  if (error) return <p>API ERROR</p> 
  */

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Product?"
        okButton="Yes"
        title="Continue remove"
      />
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchStocks()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <StockDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setStock={setStock}
            data={stock}
            open={open}
            refetchStocks={refetchStocks}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setStock}
            tableData={stocks}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
