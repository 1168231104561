import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles, fade } from '@material-ui/core/styles'
import { FixedSizeList, VariableSizeList } from 'react-window'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListSubheader from '@material-ui/core/ListSubheader'

/* const useStylesReddit = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}))

function RedditTextField(props) {
  const classes = useStylesReddit()

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  )
} */

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const LISTBOX_PADDING = 8 // px

function renderRow(props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const itemCount = Array.isArray(children) ? children.length : 0
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return 30
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return children.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          style={{ whiteSpace: 'nowrap' }}
          itemData={children}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          key={itemCount}
          outerElementType={OuterElementType}
          /* innerElementType="ul" */
          itemSize={(index) => getChildSize(children[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

ListboxComponent.propTypes = {
  children: PropTypes.node,
}

const useStyles = makeStyles((theme) => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  inputroot: {
    padding: '0!important',
  },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function AutocompleteField(props) {
  const classes = useStyles()
  /* 
  const classesReddit = useStylesReddit() */

  return (
    <Autocomplete
      {...props}
      classes={{
        root: classes.rootBootstrap,
        inputRoot: classes.inputBootstrap,
      }}
      autoHighlight={true}
      autoSelect={true}
      size="small"
      openOnFocus={true}
      disableListWrap
      disableClearable={true}
      ListboxComponent={ListboxComponent}
      renderInput={(params) => (
        <TextField
          /* variant="filled" */
          inputRef={props.inputRef}
          label={props.label}
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            /* classes: classesReddit,
            disableUnderline: true, */
            disableUnderline: true,
            ...params.InputProps,
            autoComplete: 'off',
            endAdornment: (
              <React.Fragment>
                {props.loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          margin="dense"
          fullWidth
        />
      )}
    />
  )
}
