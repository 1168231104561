import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import useForm from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import TextFieldDisplay from '../common/textFieldDisplay'
import { useMutation, useQuery } from '@apollo/react-hooks'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import ConfirmationDialog from '../common/confirmationDialog'

import {
  GET_PRICELIST,
  INSERT_PRICELIST,
  UPDATE_PRICELIST,
  REMOVE_PRICELIST,
} from '../graphql/product'
import Cookies from 'js-cookie'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  formControl: {
    marginTop: 5,
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

// START Cancelled
export default function ProductPriceDialog(props) {
  const classes = useStyles()
  const [confirm, setConfirm] = useState(false)
  const [uom, setUom] = useState('piece')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()

  const addCache = (cache, { data }) => {
    console.log('product2', props.product)
    const old = cache.readQuery({
      query: GET_PRICELIST,
      variables: { ProductID: props.product && props.product.id },
    })
    const latest = data.insertprice
    cache.writeQuery({
      query: GET_PRICELIST,
      variables: { ProductID: props.product && props.product.id },
      data: { pricelist: [latest, ...old.pricelist] },
    })
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRICELIST,
      variables: { ProductID: props.product && props.product.id },
    })
    const latest = data.updateprice
    const foundIndex = old.pricelist.findIndex((item) => item.id === latest.id)
    old.pricelist.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_PRICELIST,
      variables: { ProductID: props.product && props.product.id },
      data: { pricelist: [...old.pricelist] },
    })
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRICELIST,
      variables: { ProductID: props.product && props.product.id },
    })
    if (data.removeprice) {
      const latest = old.pricelist.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_PRICELIST,
        variables: { ProductID: props.product && props.product.id },
        data: { pricelist: [...latest] },
      })
      props.setSelected()
      props.setOpen(false)
    }
  }

  const [insertprice] = useMutation(INSERT_PRICELIST, { update: addCache })
  const [updateprice] = useMutation(UPDATE_PRICELIST, {
    update: updateCache,
  })
  const [removeprice] = useMutation(REMOVE_PRICELIST, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    if (!props.product) return

    if (!props.data) {
      insertprice({
        variables: {
          ProductID: props.product && props.product.id,
          /* Unit: parseInt(values.Unit), */
          Unit: 1,
          UnitPrice: parseFloat(values.UnitPrice),
          Uom: values.Uom || 'each',
        },
      })
    } else {
      updateprice({
        variables: {
          id: props.data.id,
          /* Unit: parseInt(values.Unit), */
          Unit: 1,
          UnitPrice: parseFloat(values.UnitPrice),
          Uom: values.Uom || 'each',
        },
      })

      props.setSelected()
    }

    props.setOpen(false)
  }

  const handleClickDelete = () => {
    removeprice({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    console.log('product', props.product)
    if (props.data) setUom(props.data.Uom)
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Price?"
        okButton="Yes"
        title="Continue remove"
      />

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            Price
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item md={9} xs={12}>
                  {/* <TextField
                    autoFocus
                    fullWidth
                    name="Unit"
                    label="Quantity"
                    margin="dense"
                    type="number"
                    autoComplete="off"
                    defaultValue={props.data && props.data.Unit}
                    inputRef={register({ required: 'Required' })}
                    error={errors.Unit}
                    helperText={errors.Unit && errors.Unit.message}
                  />
                </Grid>

                <Grid item md={8} xs={12}> */}
                  <TextField
                    autoFocus
                    fullWidth
                    name="Uom"
                    label="Option"
                    margin="dense"
                    autoComplete="off"
                    placeholder="eg.500 gram"
                    defaultValue={props.data && props.data.Uom}
                    inputRef={register({})}
                    error={errors.Uom}
                    helperText={errors.Uom && errors.Uom.message}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid style={{ paddingBottom: 0 }} item md={6} xs={12}>
                  <TextField
                    name="UnitPrice"
                    label="Price"
                    margin="dense"
                    autoComplete="off"
                    defaultValue={props.data && props.data.UnitPrice}
                    inputRef={register({ required: 'Required' })}
                    error={errors.UnitPrice}
                    helperText={errors.UnitPrice && errors.UnitPrice.message}
                  />
                </Grid>
              </Grid>
              {/* 
              <pre>{JSON.stringify(props, null, 4)}</pre> */}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <IconButton
                style={{ marginRight: 10 }}
                onClick={() => {
                  setConfirm(true)
                }}
                edge="end"
                aria-label="comments"
              >
                <DeleteIcon />
              </IconButton>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
