import React, { useEffect, useState } from 'react'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import TransactionOrderDialog from './transactionOrderDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { TRANSACTION_HISTORY } from '../graphql/transaction'
import ConfirmationDialog from '../common/confirmationDialog'
import TransactionDialog from './transactionDialog'
import GetAppIcon from '@material-ui/icons/GetApp'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import DatepickerField from '../common/datepickerField'
import Moment from 'moment-timezone'
import { useSnackbar } from 'notistack'
import Cookies from 'js-cookie'

const restApi2 = 'https://frapp.cloud/fishfarm'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'TransactionDate',
    datetime: true,
    label: 'Tns Date',
  } /* ,
  {
    id: 'DebitCredit',
    label: 'Tns Type',
  } */,
  {
    id: 'TransactionLocation',
    label: 'Tns Location',
  },
  {
    id: 'PaymentMode',
    label: 'Payment Mode',
  },
  {
    id: 'BankName',
    label: 'Bank Name',
    uppercase: true,
  },
  {
    id: 'PaymentCCName',
    label: 'Card Holder',
  },
  {
    id: 'PaymentCCNo',
    label: 'Card No',
  },
  {
    id: 'OrderNo',
    label: 'Order No',
  },
  {
    id: 'BuyerName',
    address: true,
    label: 'Client',
  },
  {
    id: 'TransactionDesc',
    label: 'Item Desc',
  },
  {
    id: 'Qty',
    label: 'Qty',
    numeric: true,
  },
  {
    id: 'UnitPrice',
    label: 'Unit Price',
    currency: true,
    numeric: true,
  },
  {
    id: 'TransactionAmount',
    label: 'Total Amount',
    currency: true,
    numeric: true,
  },
  {
    id: 'ServiceTimes',
    label: 'Booking Pass',
    numeric: true,
  },
  {
    id: 'Client',
    label: 'Branch',
  } /* ,
  {
    id: 'RefundBy',
    logdate: 'RefundOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Refund',
  } ,
  {
    id: 'RefundReason',
    label: 'Refund Desc',
  },*/,
]

export default function Transaction() {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openRefund, setOpenRefund] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [transaction, setTransaction] = useState()
  const [currentDate, setCurrentDate] = useState(new Date())
  const [from, setFrom] = useState(
    new Date(currentDate.setMonth(currentDate.getMonth() - 1))
  )
  const [to, setTo] = useState(new Date())
  const [totalAmount, setTotalAmount] = useState()
  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.936lae.com')
      ? window.location.host.split('.936lae.com')[0]
      : 'spa2'
  )
  //const [dbhost, setDbhost] = useState('ezjb')

  const {
    loading,
    data: { transactionhistory } = { transactionhistory: [] },
    refetch: refetchTransaction,
  } = useQuery(TRANSACTION_HISTORY, { skip: !from || !to })

  const handleClickOpen = () => {
    setTransaction()
    setSelected([])
    setOpen(true)
  }
  const handleClickSearch = () => {
    if (from && to) refetchTransaction({ FromDate: from, ToDate: to })
  }

  const handleSaveCsv = () => {
    const fromDate = Moment(from).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')
    const toDate = Moment(to).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD')
    console.log(
      `${restApi2}/salestransaction_csv.php?client=${dbhost}&from=${fromDate}&to=${toDate}&staffID=${Cookies.get(
        'StaffID'
      )}`
    )
    window.open(
      `${restApi2}/salestransaction_csv.php?client=${dbhost}&from=${fromDate}&to=${toDate}&staffID=${Cookies.get(
        'StaffID'
      )}`,
      '_blank'
    )
  }

  useEffect(() => {
    const total = transactionhistory.reduce(
      (a, b) => a + parseInt(b.TransactionAmount),
      0
    )
    setTotalAmount(total)
  }, [transactionhistory])

  ////if (loading) return <Loading />

  return (
    <div className={classes.root}>
      {/* <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button> */}
      {/* <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchTransaction()
        }}
      >
        Refresh
      </Button> */}

      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          <DatepickerField
            name="From"
            label="From"
            value={from ? from : null}
            fullWidth
            format={'MMM, DD YYYY'}
            onChange={(value) => {
              setFrom(Moment(value).tz('Asia/Kuala_Lumpur'))
            }}
            autoComplete="off"
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <DatepickerField
            name="To"
            label="To"
            format={'MMM, DD YYYY'}
            value={to ? to : null}
            fullWidth
            onChange={(value) => {
              setTo(Moment(value).tz('Asia/Kuala_Lumpur'))
            }}
            autoComplete="off"
          />
        </Grid>

        <Grid item md={3} xs={12}>
          <Button
            disabled={!to || !from}
            variant="contained"
            disableElevation
            style={{ marginTop: 20 }}
            color="primary"
            onClick={handleClickSearch}
          >
            Search
          </Button>
          <Button
            style={{
              marginTop: 20,
              marginLeft: 10,
            }}
            disabled={!to || !from}
            variant="contained"
            color="primary"
            type="button"
            startIcon={<GetAppIcon />}
            onClick={() => {
              handleSaveCsv()
            }}
          >
            Save CSV
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0} style={{ marginTop: 30 }}>
        <Grid item xs={12} sm={12}>
          <TransactionDialog
            key={+new Date() + Math.random()}
            setOpen={setOpenRefund}
            setSelected={setSelected}
            setTransaction={setTransaction}
            data={transaction}
            open={openRefund}
            to={to}
            handleClickSearch={handleClickSearch}
            from={from}
            refetchTransaction={refetchTransaction}
          />
          {/* <TransactionOrderDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setTransaction={setTransaction}
            data={transaction}
            open={open}
            to={to}
            from={from}
            refetchTransaction={refetchTransaction}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            rowsPerPage={10}
            clickOnSelect={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setTransaction}
            tableData={transactionhistory}
            setOpen={setOpenRefund}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
      <h3 style={{ marginTop: 0 }}>
        Total Amount: {parseInt(totalAmount).toFixed(2)}
      </h3>
    </div>
  )
}
