import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  UPDATE_LEAVE,
  LEAVE_HISTORY,
  INSERT_LEAVE,
  REMOVE_LEAVE,
} from '../graphql/leave'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [leavetype, setLeavetype] = useState()
  const leavetypes = ['Full Day', 'Half Day']
  const [confirm, setConfirm] = useState(false)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: LEAVE_HISTORY,
    })
    const latest = data.insertleave
    cache.writeQuery({
      query: LEAVE_HISTORY,
      data: { leavehistory: [latest, ...old.leavehistory] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setLeave()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: LEAVE_HISTORY,
    })

    const latest = data.updateleave

    const foundIndex = old.leavehistory.findIndex(
      (item) => item.id === latest.id
    )
    old.leavehistory.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: LEAVE_HISTORY,
      data: { leavehistory: [...old.leavehistory] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setLeave()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: LEAVE_HISTORY,
    })
    if (data.removeleave) {
      const latest = old.leavehistory.filter(
        (item) => item.id !== props.data.id
      )
      cache.writeQuery({
        query: LEAVE_HISTORY,
        data: { leavehistory: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setLeave()
    }
  }

  const [insertLeave] = useMutation(INSERT_LEAVE, { update: addCache })
  const [updateLeave] = useMutation(UPDATE_LEAVE, {
    update: updateCache,
  })
  const [removeleave] = useMutation(REMOVE_LEAVE, {
    update: deleteCache,
  })
  const handleClickDelete = () => {
    removeleave({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (!props.data) return

    setLeavetype(props.data.LeaveType)
    setFrom(props.data.FromDate)
    setTo(props.data.ToDate)
  }, [props])

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateLeave({
        variables: {
          id: props.data.id,
          LeaveType: leavetype,
          FromDate: from,
          ToDate: to,
          NoOfDays: parseFloat(values.NoOfDays),
        },
      })
    } else {
      insertLeave({
        variables: {
          LeaveType: leavetype,
          FromDate: from,
          ToDate: to,
          NoOfDays: parseFloat(values.NoOfDays),
        },
      })
    }
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Leave?"
        okButton="Yes"
        title="Continue remove"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Leave Details
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        LeaveType
                      </InputLabel>
                      <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={leavetype}
                        defaultValue={leavetype}
                        onChange={(e) => {
                          setLeavetype(e.target.value)
                        }}
                      >
                        {leavetypes.map((a, i) => (
                          <MenuItem key={i} value={a}>
                            {a}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <DatepickerField
                      name="From"
                      label="From"
                      value={from ? from : null}
                      fullWidth
                      onChange={(e, value) => {
                        setFrom(value)
                      }}
                      inputRef={register({
                        required: 'Required',
                      })}
                      autoComplete="off"
                      error={errors.From}
                      helperText={errors.From && errors.From.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatepickerField
                      name="To"
                      label="To"
                      value={to ? to : null}
                      fullWidth
                      onChange={(e, value) => {
                        setTo(value)
                      }}
                      inputRef={register({
                        required: 'Required',
                      })}
                      autoComplete="off"
                      error={errors.To}
                      helperText={errors.To && errors.To.message}
                    />
                  </Grid>
                </Grid>
                <br />

                {/* <TextField
                  name="NoOfDays"
                  label="NoOfDays"
                  margin="dense"
                  type="number"
                  fullWidth
                  defaultValue={props.data && props.data.NoOfDays}
                  style={{ width: 120 }}
                  autoComplete="off"
                  inputProps={{ min: '0', step: 'any' }}
                  inputRef={register({ required: 'Required' })}
                  error={errors.NoOfDays}
                  helperText={errors.NoOfDays && errors.NoOfDays.message}
                /> */}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
