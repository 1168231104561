import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { useMutation } from '@apollo/react-hooks'
import { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import FaceIcon from '@material-ui/icons/Face'
import DoneIcon from '@material-ui/icons/Done'
import Moment from 'moment-timezone'

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    maxWidth: '36ch',
  },
  listitem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#ffffff',
  },
  inline: {
    display: 'inline',
  },
  buttonRoot: {
    minWidth: 0,
    marginRight: 5,
    padding: 7,
  },
  textfieldRoot: {
    marginTop: 0,
    marginRight: 5,
    width: 100,
  },
  image: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  paddingleft: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
  iconbuttonroot: {
    padding: 0,
  },
  dividerroot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  colorPrimary: {
    backgroundColor: '#b2102f',
  },
}))

export default function OrderDetailsItem(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid
        style={{ border: props.data.Refund == 1 ? '2px solid #b2102f' : 0 }}
        container
        spacing={1}
      >
        <Grid item xs={12} md={12}>
          <div
            style={{
              height: 95,
              width: 100,
              float: 'left',
              textAlign: 'center',
            }}
          >
            <img
              style={{ height: 75, padding: 0, margin: 0 }}
              className={classes.image}
              src={`${restApi}/media/spa1/uploads/invoice/${props.data.ProductImage}`}
            />
          </div>
          <div>
            <h3
              style={{
                fontWeight: 'bold',
                marginTop: 5,
                marginBottom: 3,
                lineHeight: '1.2',
                color: '#b2102f',
                fontSize: 14,
              }}
            >
              {' '}
              {props.data.ProductName}
              {props.data.ProductName2 && (
                <h5 style={{ marginTop: 0, marginBottom: 5 }}>
                  {props.data.ProductName2}
                </h5>
              )}
            </h3>

            <div
              style={{
                fontWeight: 'bold',
                margin: 0,
                paddingTop: 0,
                paddingBottom: 0,
                color: 'black',
                textAlign: 'left',
                paddingRight: 3,
              }}
            >
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 60,
                }}
              >
                Unit Price
              </span>
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 10,
                }}
              >
                :{'  '}
              </span>
              RM {parseFloat(props.data.UnitPrice).toFixed(2)}
              <br />
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 60,
                }}
              >
                Quantity
              </span>
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 10,
                }}
              >
                :{'  '}
              </span>
              <span
                style={{
                  textAlign: 'center',
                  display: 'inline-block',
                  fontWeight: 'bold',
                }}
              >
                {props.data.Qty}
              </span>
              <br />
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 60,
                }}
              >
                Total
              </span>
              <span
                style={{
                  fontWeight: 'normal',
                  display: 'inline-block',
                  width: 10,
                }}
              >
                {' '}
                :
              </span>
              <span
                style={{
                  textAlign: 'center',
                  display: 'inline-block',
                  fontWeight: 'bold',
                }}
              >
                RM {parseFloat(props.data.InvoiceAmount).toFixed(2)}
              </span>
              {props.data.Refund == 1 && (
                <React.Fragment>
                  <br />
                  <Chip
                    classes={{ colorPrimary: classes.colorPrimary }}
                    size="small"
                    label={`This item is refunded on ${`${Moment(
                      props.data && props.data.RefundOn
                    ).format('L')} ${Moment(
                      props.data && props.data.RefundOn
                    ).format('LT')}`} by ${props.data.RefundBy}`}
                    color="primary"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          {/* <Divider classes={{ root: classes.dividerroot }} /> */}
        </Grid>
        {/* 
        <TextField
          name="Quantity"
          label=""
          classes={{ root: classes.textfieldRoot }}
          margin="dense"
          variant="outlined"
          value={props.data.Qty}
          type="number"
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value)
              updateCart({
                variables: {
                  ProductID: props.data.ProductID,
                  PriceID: props.data.PriceID,
                  Qty: parseInt(e.target.value),
                },
              })
          }}
          inputProps={{ min: '0', step: 'any' }}
        /> */}
      </Grid>
    </React.Fragment>
  )
}
