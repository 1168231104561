import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const DatepickerField = (props) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...props} /* 
      InputLabelProps={{
        shrink: true,
      }}
      inputVariant="filled" */
      /* disableToolbar
      variant="inline" */
      variant="dialog"
      disableToolbar={false}
      format="dd/MM/yyyy"
      margin="dense"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  </MuiPickersUtilsProvider>
)

export default DatepickerField
