import { gql } from 'apollo-boost'

export const GET_PAYROLSTAFFS = gql`
  query PayrollStaffs($FromDate: Date, $ToDate: Date) {
    payrollstaffs(FromDate: $FromDate, ToDate: $ToDate) {
      id
      InvoiceAmount
      StaffName
      StaffID
    }
  }
`

export const GET_PAYROLLORDERS = gql`
  query PayrollOrders($UserID: ID, $FromDate: Date, $ToDate: Date) {
    payrollorders(UserID: $UserID, FromDate: $FromDate, ToDate: $ToDate) {
      id
      OrderID
      OrderNo
      UserID
      ProductID
      InvoiceAmount
      Qty
      CreatedDate
      PriceID
      UnitPrice
      ProductName
      Category
      ProductImage
      Status
      SalesPerson
      SalesClient
      Error
    }
  }
`

export const GET_PAYROLLCOLLECTIONS = gql`
  query PayrollCollections($UserID: ID, $FromDate: Date, $ToDate: Date) {
    payrollcollections(UserID: $UserID, FromDate: $FromDate, ToDate: $ToDate) {
      id
      OrderNo
      CustomerCode
      CustomerName
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DateLapse
      FirstName
      LastName
      Phone
      Email
      Address1
      Address2
      State
      City
      PostCode
      DeliveryCharges
      TotalAmount
      FinalTotal
      PaymentMethod
      Received
      ReceivedBy
      ReceivedOn
      Client
      Error
    }
  }
`

export const GET_PAYROLLRATINGS = gql`
  query PayrollRatings($UserID: ID, $FromDate: Date, $ToDate: Date) {
    payrollratings(UserID: $UserID, FromDate: $FromDate, ToDate: $ToDate) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      Error
      ServiceTimes
      Rating
      RatingOn
    }
  }
`

export const GET_PAYROLLBOOKINGS = gql`
  query PayrollBookingss($UserID: ID, $FromDate: Date, $ToDate: Date) {
    payrollbookings(UserID: $UserID, FromDate: $FromDate, ToDate: $ToDate) {
      id
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      Error
      ServiceTimes
    }
  }
`

export const GET_PAYROLLS = gql`
  query PayrollHeaders($UserID: ID) {
    payrollheaders(UserID: $UserID) {
      id
      UserID
      FromDate
      ToDate
      Status
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const GET_PAYROLLDETAILS = gql`
  query PayrollDetails($PayrollID: ID) {
    payrolldetails(PayrollID: $PayrollID) {
      id
      PayrollID
      Title
      Amount
      Error
    }
  }
`

export const UPDATE_PAYROLL = gql`
  mutation UpdatePayrollHeader($id: ID, $UserID: ID, $From: Date, $To: Date) {
    updatepayrollheader(id: $id, UserID: $UserID, From: $From, To: $To) {
      id
      UserID
      FromDate
      ToDate
      Status
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`
export const INSERT_PAYROLL = gql`
  mutation InsertPayrollHeader($UserID: ID, $From: Date, $To: Date) {
    insertpayrollheader(UserID: $UserID, From: $From, To: $To) {
      id
      UserID
      FromDate
      ToDate
      Status
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const REMOVE_PAYROLL = gql`
  mutation RemovePayroll($id: ID!) {
    removepayrollheader(id: $id)
  }
`

export const UPDATE_PAYROLLDETAIL = gql`
  mutation UpdatePayrollDetail(
    $id: ID
    $PayrollID: ID
    $Title: String
    $Amount: Float
  ) {
    updatepayrolldetail(
      id: $id
      PayrollID: $PayrollID
      Title: $Title
      Amount: $Amount
    ) {
      id
      PayrollID
      Title
      Amount
      Error
    }
  }
`
export const INSERT_PAYROLLDETAIL = gql`
  mutation InsertPayrollHeader($PayrollID: ID, $Title: String, $Amount: Float) {
    insertpayrolldetail(PayrollID: $PayrollID, Title: $Title, Amount: $Amount) {
      id
      PayrollID
      Title
      Amount
      Error
    }
  }
`

export const REMOVE_PAYROLLDETAIL = gql`
  mutation RemovePayrollDetail($id: ID!) {
    removepayrolldetail(id: $id)
  }
`
