import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  UPDATE_ORDER,
  GET_ORDERS,
  CREATE_ORDER,
  REMOVE_ORDER,
  GET_ORDERDETAILS,
  GET_ORDER,
  RECEIVED_ORDER,
} from '../graphql/order'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'
import BookingDetailsItem from './orderDetailsItem'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

// START
function OrderDetails(props) {
  const classes = useStyles()

  const token = props.match.params.token
  const {
    loading: loadingOrderDetails,
    data: { orderdetails } = { orderdetails: [] },
  } = useQuery(GET_ORDERDETAILS, {
    variables: { Token: token },
    skip: !token,
  })

  const {
    loading: loadingOrder,
    data: { order } = { order: {} },
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { Token: token },
    skip: !token,
  })

  const updateCache = (cache, { data }) => {
    refetch()
  }

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.createorder
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [latest, ...old.orders] },
    })
  }

  const [createOrder] = useMutation(CREATE_ORDER, { update: addCache })
  const [receivedOrder] = useMutation(RECEIVED_ORDER, {
    update: updateCache,
  })

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    /* if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } */
  }

  const handleClickCollected = () => {
    receivedOrder({
      variables: {
        Token: token,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (orderdetails.length == 0) return

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails])

  useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount])

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <ConfirmationDialog
          action={handleClickCollected}
          confirm={confirm}
          setConfirm={setConfirm}
          message="Client Collected Order?"
          okButton="Yes"
          title="Item Collected"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={6}>
              <TextFieldDisplay
                label="Total"
                value={`RM ${parseFloat(totalAmount).toFixed(2)}`}
              />
            </Grid>
            <Grid item md={6} xs={6}>
              <TextFieldDisplay
                label="Date"
                value={`${Moment(order.CreatedOn).format('L')} ${Moment(
                  order.CreatedOn
                ).format('LT')}`}
              />
            </Grid>
            {/* 
          <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
            <TextFieldDisplay
              label="Order Total"
              value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
            />
          </Grid> */}
          </Grid>
          <Divider classes={{ root: classes.dividerroot }} />
          <Grid container spacing={3}>
            <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
              <TextFieldDisplay
                label="Name"
                value={`${order.FirstName} ${order.LastName}`}
              />

              <TextFieldDisplay label="Phone" value={order.Phone} />

              <TextFieldDisplay label="Email" value={order.Email} />
            </Grid>
            <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
              <TextFieldDisplay label="Address 1" value={order.Address1} />

              <TextFieldDisplay label="Address 2" value={order.Address2} />

              <TextFieldDisplay label="City" value={order.City} />

              <TextFieldDisplay label="Postcode" value={order.PostCode} />

              <TextFieldDisplay label="State" value={order.State} />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(order, 0, 4)}</pre> */}
          <Divider classes={{ root: classes.dividerroot }} />
          {orderdetails.map(
            (order, i) =>
              order.Category == '1' && (
                <BookingDetailsItem key={i} data={order} />
              )
          )}
          {/*  {JSON.stringify(orderdetails, 0, 0, 4)} */}
        </form>
        {!order.Received && (
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => {
              setConfirm(true)
            }}
          >
            Item Collected
          </Button>
        )}
        {order.Received == 'Received' && (
          <Alert severity="success">
            Item collected. Serviced by {order.ReceivedBy}{' '}
          </Alert>
        )}
      </Container>
    </div>
  )
}

export default OrderDetails
