import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'

export default function Loading() {
  return (
    <Box pt={0.1}>
      <Skeleton height={10} style={{ marginBottom: 6 }} />
      <Skeleton height={10} width="80%" />
    </Box>
  )
}
