import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  INSERT_OFFICESTAFF,
  UPDATE_OFFICESTAFF,
  REMOVE_OFFICESTAFF,
  GET_OFFICESTAFFS,
} from '../graphql/officestaff'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import { useSnackbar } from 'notistack'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

export default function OfficeStaffDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setError } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [department, setDepartment] = useState('Staff')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const addCache = (cache, { data }) => {
    const latest = data.insertofficestaff

    if (latest.Error == 'Username exist') {
      setError('Username', true, 'Username exist. Try another Username.')
      return
    }

    const old = cache.readQuery({
      query: GET_OFFICESTAFFS,
    })

    cache.writeQuery({
      query: GET_OFFICESTAFFS,
      data: { officestaffs: [latest, ...old.officestaffs] },
    })

    enqueueSnackbar('Staff created', {
      variant: 'success',
    })

    props.setOpen(false)
    props.setSelected([])
    props.setOfficestaff()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_OFFICESTAFFS,
    })

    const latest = data.updateofficestaff

    const foundIndex = old.officestaffs.findIndex(
      (item) => item.id === latest.id
    )
    old.officestaffs.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_OFFICESTAFFS,
      data: { officestaffs: [...old.officestaffs] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setOfficestaff()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_OFFICESTAFFS,
    })
    if (data.removeofficestaff) {
      const latest = old.officestaffs.filter(
        (item) => item.id !== props.data.id
      )
      cache.writeQuery({
        query: GET_OFFICESTAFFS,
        data: { officestaffs: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setOfficestaff()
    }
  }

  const [insertOfficestaf] = useMutation(INSERT_OFFICESTAFF, {
    update: addCache,
  })
  const [updateOfficestaff] = useMutation(UPDATE_OFFICESTAFF, {
    update: updateCache,
  })
  const [removeofficestaf] = useMutation(REMOVE_OFFICESTAFF, {
    update: deleteCache,
  })
  const handleClickDelete = () => {
    removeofficestaf({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (!props.data) return

    // setEmail(props.data.Email)
    // setPhone(props.data.Phone)
    // setFirstName(props.data.FirstName)
    setDepartment(props.data.Department)
  }, [props])

  const onSubmit = (values) => {
    if (!department) return

    if (props.data && props.data.id) {
      updateOfficestaff({
        variables: {
          id: props.data.id,
          Email: values.Email,
          Phone: values.Phone,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          Postcode: values.Postcode,
          State: values.State,
          Country: 'Malaysia',
          Password: values.Password,
          Department: department,
        },
      })
    } else {
      if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g.test(values.Username)) {
        setError(
          'Username',
          true,
          'Username must not have special characters or space'
        )
        return
      }

      insertOfficestaf({
        variables: {
          Email: values.Email,
          Phone: values.Phone,
          FirstName: values.FirstName,
          LastName: values.LastName,
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          Postcode: values.Postcode,
          State: values.State,
          Country: 'Malaysia',
          Username: values.Username,
          Password: values.Password,
          Department: department,
        },
      })
    }
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Staff?"
        okButton="Yes"
        title="Continue remove"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Staff Details
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={department}
                      defaultValue={department}
                      onChange={(e) => {
                        setDepartment(e.target.value)
                      }}
                    >
                      <MenuItem value="Staff">Staff</MenuItem>
                      <MenuItem value="Spa Consultant">Spa Consultant</MenuItem>
                      <MenuItem value="Branch Manager">Branch Manager</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="HR">HR</MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Username"
                      label="Username"
                      margin="dense"
                      InputProps={{
                        readOnly: props.data && true,
                      }}
                      fullWidth
                      defaultValue={props.data && props.data.Username}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Username}
                      helperText={errors.Username && errors.Username.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Password"
                      label="Password"
                      margin="dense"
                      fullWidth
                      autoComplete="off"
                      inputRef={
                        props.data
                          ? register({})
                          : register({ required: 'Required' })
                      }
                      error={errors.Password}
                      helperText={`leave blank if no change`}
                    />
                  </Grid>
                </Grid>

                <Divider className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Email"
                      label="Email"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Email}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Email}
                      helperText={errors.Email && errors.Email.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Phone"
                      label="Phone"
                      margin="dense"
                      type="number"
                      fullWidth
                      defaultValue={props.data && props.data.Phone}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Phone}
                      helperText={errors.Phone && errors.Phone.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="FirstName"
                      label="First Name"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.FirstName}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.FirstName}
                      helperText={errors.FirstName && errors.FirstName.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="LastName"
                      label="Last Name"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.LastName}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.LastName}
                      helperText={errors.LastName && errors.LastName.message}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="Address1"
                      label="Address 1"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Address1}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Address1}
                      helperText={errors.Address1 && errors.Address1.message}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      name="Address2"
                      label="Address 2"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Address2}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Address2}
                      helperText={errors.Address2 && errors.Address2.message}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="City"
                      label="City"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.City}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.City}
                      helperText={errors.City && errors.City.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Postcode"
                      label="Postcode"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Postcode}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Postcode}
                      helperText={errors.Postcode && errors.Postcode.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="State"
                      label="State"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.State}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.State}
                      helperText={errors.State && errors.State.message}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      name="Country"
                      label="Country"
                      margin="dense"
                      fullWidth
                      defaultValue={props.data && props.data.Country}
                      autoComplete="off"
                      inputRef={register({ required: 'Required' })}
                      error={errors.Country}
                      helperText={errors.Country && errors.Country.message}
                    />
                  </Grid>
                </Grid>
                <br />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <IconButton
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setConfirm(true)
                  }}
                  edge="end"
                  aria-label="comments"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
