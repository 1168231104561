import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'

import { UPDATE_STOCK, CREATE_STOCK, GET_STOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import {
  GET_PAYROLLS,
  INSERT_PAYROLL,
  REMOVE_PAYROLL,
  UPDATE_PAYROLL,
} from '../graphql/payroll'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'

const restApi = process.env.REACT_APP_API

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function OfficeStaffPayrollDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const [confirm, setConfirm] = useState(false)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [qty, setQty] = useState(1)
  const [productFull, setProductFull] = useState()
  const [product, setProduct] = useState()
  const [productName, setProductName] = useState()
  const [price, setPrice] = useState()
  const [selectedPrice, setSelectedPrice] = useState()

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.staff,
      },
    })
    const latest = data.insertpayrollheader
    cache.writeQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.staff,
      },
      data: { payrollheaders: [latest, ...old.payrollheaders] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setPayroll()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.staff,
      },
    })

    const latest = data.updatepayrollheader

    const foundIndex = old.payrollheaders.findIndex(
      (item) => item.id === latest.id
    )
    old.payrollheaders.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.staff,
      },
      data: { payrollheaders: [...old.payrollheaders] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setPayroll()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.staff,
      },
    })
    if (data.removepayrollheader) {
      const latest = old.payrollheaders.filter(
        (item) => item.id !== props.data.id
      )
      cache.writeQuery({
        query: GET_PAYROLLS,
        variables: {
          UserID: props.staff,
        },
        data: { payrollheaders: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setPayroll()
    }
  }

  const [insertPayroll] = useMutation(INSERT_PAYROLL, { update: addCache })
  const [updatePayroll] = useMutation(UPDATE_PAYROLL, {
    update: updateCache,
  })
  const [removePayroll] = useMutation(REMOVE_PAYROLL, {
    update: deleteCache,
  })

  const handleClickDelete = () => {
    removePayroll({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updatePayroll({
        variables: {
          UserID: props.staff,
          id: props.data.id,
          From: from,
          To: to,
        },
      })
    } else {
      insertPayroll({
        variables: {
          UserID: props.staff,
          From: from,
          To: to,
        },
      })
    }
  }

  useEffect(() => {
    if (!props.data) return
  }, [props])

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Payroll item?"
        okButton="Yes"
        title="Continue remove"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Payroll
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <DatepickerField
                      name="From"
                      label="From"
                      value={from ? from : null}
                      fullWidth
                      onChange={(e, value) => {
                        setFrom(value)
                      }}
                      inputRef={register({
                        required: 'Required',
                      })}
                      autoComplete="off"
                      error={errors.From}
                      helperText={errors.From && errors.From.message}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <DatepickerField
                      name="To"
                      label="To"
                      value={to ? to : null}
                      fullWidth
                      onChange={(e, value) => {
                        setTo(value)
                      }}
                      inputRef={register({
                        required: 'Required',
                      })}
                      autoComplete="off"
                      error={errors.To}
                      helperText={errors.To && errors.To.message}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {props.data && (
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
