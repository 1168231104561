import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function DraggableDialog(props) {
  return (
    <Dialog
      open={props.confirm}
      onClose={() => {
        props.setConfirm(false)
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {props.title || 'Confirm'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.message || 'Are you sure ?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disableElevation
          autoFocus
          onClick={() => {
            props.setConfirm(false)
          }}
          color="primary"
        >
          Cancel
        </Button>
        {props.okButton && (
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              props.action()
            }}
            color="primary"
          >
            {props.okButton || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
