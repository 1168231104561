import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useForm from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'

import TextField from '@material-ui/core/TextField'
import { GET_SHOPCONFIG, UPDATE_CONFIG } from '../graphql/config'
import { useSnackbar } from 'notistack'
import { useMutation } from '@apollo/react-hooks'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

// START
export default function ConfigTinyMCEDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()
  const [formValue, setFormValue] = useState()

  const updateCache = (cache, { data }) => {
    props.refetch()
  }

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    update: updateCache,
  })

  const onSubmit = (values) => {
    updateConfig({
      variables: {
        formKey: props.formKey,
        formValue: formValue,
      },
    })
    props.setOpen(false)
  }

  useEffect(() => {
    if (props.formValue) setFormValue(props.formValue)
  }, [props])

  const handleEditorChange = (e) => {
    setFormValue(e.target.getContent())
    //console.log('Content was updated:', e.target.getContent())
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            {props.formTitle}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Editor
                    apiKey="ddwtpmbv25bslslv86dfnxcn8n9tzcdg44odrmgp2h9phjty"
                    initialValue={props.formValue}
                    init={{
                      height: 600,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent',
                    }}
                    onChange={handleEditorChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
