import { gql } from 'apollo-boost'
/* 
export const GET_CARTS = gql`
  {
    carts {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      UnitPrice
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
    }
  }
` */

export const GET_CARTS = gql`
  query Carts($UserID: ID) {
    carts(UserID: $UserID) {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
      Category
      SalesPerson
      SalesClient
    }
  }
`

export const INSERT_CART = gql`
  mutation InsertCart(
    $UserID: ID
    $SalesPerson: String
    $ProductID: ID
    $Qty: Int
    $PriceID: ID
  ) {
    insertcart(
      UserID: $UserID
      SalesPerson: $SalesPerson
      ProductID: $ProductID
      Qty: $Qty
      PriceID: $PriceID
    ) {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
      Category
      SalesPerson
      SalesClient
    }
  }
`
export const UPDATE_CART = gql`
  mutation UpdateCart(
    $UserID: ID
    $id: ID
    $ProductID: ID
    $Qty: Int
    $PriceID: ID
  ) {
    updatecart(
      id: $id
      UserID: $UserID
      ProductID: $ProductID
      Qty: $Qty
      PriceID: $PriceID
    ) {
      id
      OrderID
      UserID
      ShipperID
      ProductID
      ProductNo
      OrderDate
      RequiredDate
      Freight
      SalesTax
      TransactStatus
      InvoiceAmount
      PaymentDate
      Qty
      ProductName
      ProductImage
      PriceID
      UnitPrice
      ProductPrices
      ProductDesc
      Category
      SalesPerson
      SalesClient
    }
  }
`
export const REMOVE_CART = gql`
  mutation RemoveCart($id: ID) {
    removecart(id: $id)
  }
`
