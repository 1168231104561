import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import { GET_BUYERS } from '../graphql/buyer'
import ConfirmationDialog from '../common/confirmationDialog'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import PrintIcon from '@material-ui/icons/Print'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AutocompleteField from '../common/autocompleteField'
import Loading from '../common/loading'
import HealthreportUploadDialog from './healthreportUploadDialog'
import Report from './report'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

export default function HealthReport() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openHeader, setOpenHeader] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const {
    loading: loadingBuyers,
    data: { buyers } = { buyers: [] },
    error,
  } = useQuery(GET_BUYERS)

  const [customer, setCustomer] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [quotation, setQuotation] = useState()

  const handleClickOpen = () => {
    /* setQuotation()
    setSelected([])
    setOpenHeader(true) */
    setOpenUpload(true)
  }

  const handlePrint = () => {
    setOpen(true)
  }

  /* 
  const handleClickActive = () => {
    setActive({
      variables: {
        id: selected[0],
        CustomerCode: customer,
      },
    })
  } */

  const tableHead = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'QuotationNo',
      numeric: false,
      disablePadding: true,
      label: 'Quotation No',
    },
    {
      id: 'AirlineCode',
      numeric: false,
      disablePadding: true,
      label: 'Airline',
    },
    {
      id: 'Active',
      numeric: false,
      disablePadding: true,
      label: 'Active',
    },
  ]

  /* const tableButtons = [
    {
      name: 'Active',
      icon: EmojiObjectsIcon,
      method: handleClickActive,
    },
    {
      name: 'Set Prices & Print Quotation',
      icon: AttachMoneyIcon,
      method: handlePrint,
    },
  ] */

  if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      {/*  <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Quote?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <HealthreportUploadDialog
        customer={customer}
        open={openUpload}
        setOpen={setOpenUpload}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          {loadingBuyers && <Loading />}
          {!loadingBuyers && (
            <AutocompleteField
              name="buyer"
              label="Customer"
              value={customerName}
              options={buyers.map(
                (item) => `${item.Phone} — ${item.FirstName} ${item.LastName}`
              )}
              onChange={(e, value) => {
                const buyerValue = buyers.find(
                  (item) =>
                    `${item.Phone} — ${item.FirstName} ${item.LastName}` ===
                    value
                )
                if (buyerValue) {
                  setCustomer(buyerValue.UserID)
                  setCustomerName(
                    `${buyerValue.Phone} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                  )
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {customer && (
          <Button
            variant="contained"
            style={{ marginTop: 30 }}
            classes={{ root: classes.newButton }}
            color="primary"
            onClick={handleClickOpen}
          >
            Upload
          </Button>
        )}
        <Grid item xs={12} sm={12}>
          <Report key={+new Date() + Math.random()} customer={customer} />

          {/* <QuotationHeaderDialog
            key={+new Date() + Math.random()}
            setOpenHeader={setOpenHeader}
            setSelected={setSelected}
            setQuotation={setQuotation}
            data={quotation}
            customer={customer}
            openHeader={openHeader}
          />

          <QuotationDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setQuotation={setQuotation}
            data={quotation}
            open={open}
          /> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          {/*  {!loading && customer && quotations.length === 0 && (
            <p>No Quotation found.</p>
          )}
          {!loading && customer && quotations.length > 0 && (
            <TableHalf
              tableButtons={tableButtons}
              setConfirm={setConfirm}
              selected={selected}
              setSelected={setSelected}
              tableState={setQuotation}
              tableData={quotations}
              setOpen={setOpenHeader}
              tableHead={tableHead}
            ></TableHalf>
          )} */}
        </Grid>
      </Grid>
    </div>
  )
}
