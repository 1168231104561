import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextFieldDisplay from '../common/textFieldDisplay'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import PrintIcon from '@material-ui/icons/Print'
import EditIcon from '@material-ui/icons/Edit'
import { Divider } from '@material-ui/core'
import {
  ColorPicker,
  ColorPalette,
  ColorInput,
  ColorBox,
} from 'material-ui-color'
import { GET_SHOPCONFIG, UPDATE_CONFIG } from '../graphql/config'
import { useMutation } from '@apollo/react-hooks'

const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  displayDiv: {
    background: theme.palette.background.paper,
    minHeight: '340px',
    margin: 20,
    marginTop: 30,
  },
  small: {
    width: 300,
  },
  big: {
    width: 800,
  },
  dialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
  },
  table: {
    padding: 0,
  },
}))

const ShopAddressDisplay = (props) => {
  const classes = useStyles()
  const [dbhost, setDbhost] = useState('spa2')
  const [color, setColor] = useState('#000')
  //const [dbhost, setDbhost] = useState('storedemo1')

  const updateCache = (cache, { data }) => {
    props.refetch()
    props.setTheme({
      palette: {
        primary: {
          dark: `#${color.hex}`,
          main: `#${color.hex}`,
          paper: '#f5f5f5',
          contrastText: '#f3f3f3',
        },
        secondary: {
          main: `#${color.hex}`,
        },
        background: {
          default: '#efefef',
        },
        type: 'light', // Switching the dark mode on is a single property value change.
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 15,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    })
  }

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    update: updateCache,
  })

  useEffect(() => {
    setColor(props.data.COLORPRIMARY)
  }, [props])

  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item spacing={5} md={6} xs={12}>
          <TextFieldDisplay
            fullWidth={false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="directions"
                    onClick={() => {
                      props.setFormKey('COMADD')
                      props.setFormValue(props.data.COMADD)
                      props.setOpen(true)
                      props.setFormTitle('Company Address')
                      props.setFormPlaceholder('')
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            multiline
            label="Company Address"
            value={`${props.data.COMADD}`}
          />
        </Grid>
        <Grid item md={6} xs={12}></Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ShopAddressDisplay
