import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import ProductDialog from './productDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { GET_REMAININGSTOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import { GET_GROUPBRANCHES } from '../graphql/branch'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
    width: 200,
  },
  {
    id: 'TotalInReduce',
    label: 'Total Stock In',
    width: 50,
  },
  {
    id: 'TotalOut',
    label: 'Total Stock Out',
    width: 50,
  },
  {
    id: 'Remaining',
    label: 'Total Remaining',
    width: 50,
  },
  {
    id: 'TotalReserve',
    label: 'Total Reserve',
    integer: true,
    width: 50,
  },
]

const tableHeadStock = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  /* {
    id: 'BranchName',
    label: 'Branch Name',
  }, */
  {
    id: 'BranchCode',
    label: 'Branch Code',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
    width: 500,
  },
  /* {
    id: 'Remaining',
    label: 'Remaining',
  }, */
]

export default function StockRemaining() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [stock, setStock] = useState()

  const {
    data: { groupbranches } = { branches: [] },
    refetch: refetchBranches,
  } = useQuery(GET_GROUPBRANCHES)

  const {
    data: { remainingstocks } = { remainingstocks: [] },
    refetch: refetchStocks,
  } = useQuery(GET_REMAININGSTOCKS)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    if (data.removeorder) {
      const latest = old.orders.filter((item) => item.id !== selected[0])
      cache.writeQuery({
        query: GET_ORDERS,
        data: { orders: [...latest] },
      })
      setSelected([])
      setStock()
    }
  }

  const [removeOrder] = useMutation(REMOVE_ORDER, {
    update: deleteCache,
  })

  const handleClickOpen = () => {
    setStock()
    setSelected([])
    setOpen(true)
  }

  const handleClickDelete = () => {
    removeOrder({
      variables: {
        id: selected[0],
      },
    })
    setConfirm(false)
    setSelected([])
    setStock()
  }

  useEffect(() => {
    remainingstocks.map((a, i) => {
      a.Remaining = a.TotalIn - (a.TotalOut + a.TotalReduce)
      a.TotalInReduce = a.TotalIn - a.TotalReduce
      a.TotalReserve = a.TotalReserve || 0
      a.TotalOut = a.TotalOut || 0
    })
    console.log(remainingstocks)
  }, [remainingstocks])

  /*
  //if (loading) return <Loading />
  if (error) return <p>API ERROR</p> 
  */

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Product?"
        okButton="Yes"
        title="Continue remove"
      />
      {/* <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchStocks()
        }}
      >
        Refresh
      </Button> */}
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchStocks()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        {remainingstocks.filter((b) => b.TotalIn - b.TotalOut < 11).length >
          0 && (
          <Grid item md={7} sm={12}>
            {/* <ProductDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setStock={setStock}
            data={stock}
            open={open}
            refetchStocks={refetchStocks}
          /> */}
            <h2 style={{ color: 'red' }}>Low Stock</h2>
            <TableHalf
              disableSelected={true}
              clickOnSelect={true}
              hidePagination={true}
              hideChange={true}
              hideDelete={true}
              hideSearch={true}
              setConfirm={setConfirm}
              selected={selected}
              setSelected={setSelected}
              tableState={setStock}
              tableData={remainingstocks.filter(
                (b) => b.TotalIn - b.TotalOut < 11
              )}
              setOpen={setOpen}
              tableHead={tableHeadStock}
            ></TableHalf>
          </Grid>
        )}
        <Grid item md={7} sm={12}>
          {groupbranches &&
            groupbranches.map((a, i) => {
              return (
                <div key={i}>
                  <h3
                    style={{ marginTop: 30, marginBottom: 10 }}
                  >{`${a.BranchCode} - ${a.BranchName}`}</h3>
                  <TableHalf
                    disableSelected={true}
                    clickOnSelect={true}
                    hidePagination={true}
                    hideChange={true}
                    hideDelete={true}
                    hideSearch={true}
                    setConfirm={setConfirm}
                    selected={selected}
                    setSelected={setSelected}
                    tableState={setStock}
                    tableData={
                      (remainingstocks.length > 0 && [
                        ...remainingstocks.filter(
                          (b) => b.BranchCode == a.BranchCode
                        ),
                      ]) ||
                      []
                    }
                    setOpen={setOpen}
                    tableHead={tableHead}
                  ></TableHalf>
                </div>
              )
            })}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
