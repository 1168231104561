import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Cookies from 'js-cookie'
import TabTitle from '../common/tabTItle'
import Order from './order'
import BookingSchedule from './bookingSchedule'
import OrderSchedule from './orderSchedule'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import { GET_REMAININGSTOCKS } from '../graphql/stock'
import Loading from '../common/loading'

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  container: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
  padding: {
    padding: theme.spacing(3),
  },
  border: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
    width: 200,
  },
  {
    id: 'Remaining',
    label: 'Remaining Stock',
    width: 50,
    numeric: true,
  },
  { id: 'TotalReserve', label: 'Reserved Stock', width: 50, numeric: true },
]

export default function OrderMain(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [stock, setStock] = useState()

  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.936lae.com')
      ? window.location.host.split('.936lae.com')[0]
      : 'spa2'
  )

  const {
    data: { remainingstocks } = { remainingstocks: [] },
    refetch: refetchRemaining,
    loading: loadingRemaining,
  } = useQuery(GET_REMAININGSTOCKS)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const access = {
    view1: ['Management', 'Admin', 'Spa Consultant', 'Branch Manager'],
  }

  useEffect(() => {
    remainingstocks.map((a, i) => {
      a.Remaining = a.TotalIn - (a.TotalOut + a.TotalReduce)
    })
  }, [remainingstocks])

  return (
    <React.Fragment>
      <CssBaseline />
      {access.view1.find((access) => access == Cookies.get('Department')) && (
        <Container className={classes.container}>
          <div style={{ padding: 20 }}>
            <h2>
              Stock Level{' '}
              <RefreshIcon
                fontSize="small"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  refetchRemaining()
                }}
              />
            </h2>

            <Grid container spacing={0}>
              <Grid item xs={12} md={5}>
                <TableHalf
                  disableSelected={true}
                  clickOnSelect={true}
                  hidePagination={true}
                  hideChange={true}
                  hideDelete={true}
                  hideSearch={true}
                  setConfirm={setConfirm}
                  selected={selected}
                  setSelected={setSelected}
                  tableState={setStock}
                  tableData={remainingstocks.filter(
                    (a) => a.BranchCode == dbhost
                  )}
                  setOpen={setOpen}
                  tableHead={tableHead}
                ></TableHalf>
              </Grid>
            </Grid>
          </div>
          <StyledTabs
            className={classes.border}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab label="Scan QR" {...tabProps(0)} />
            <StyledTab label="Booking" {...tabProps(1)} />
            <StyledTab label="Order" {...tabProps(2)} />
            {/*  <StyledTab label="Ship Advice" {...tabProps(1)} />
                
                <StyledTab label="Upload POD" {...tabProps(2)} /> */}
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <Order />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BookingSchedule />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <OrderSchedule refetchRemaining={refetchRemaining} />
          </TabPanel>
        </Container>
      )}
    </React.Fragment>
  )
}
