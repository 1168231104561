import { gql } from 'apollo-boost'

export const GET_STOCKS = gql`
  query Stocks($StockTakeID: ID, $LocationID: String) {
    stocks(StockTakeID: $StockTakeID, LocationID: $LocationID) {
      id
      ProductID
      ProductName
      StockTakeID
      LocationID
      Title
      Qty
      Bags
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`
export const GET_STOCKLOCATIONS = gql`
  {
    stocklocations {
      BranchCode
      BranchName
    }
  }
`
export const GET_REMAININGSTOCKS = gql`
  {
    remainingstocks {
      id
      ProductName
      BranchCode
      Title
      TotalIn
      TotalOut
      TotalReduce
      TotalReserve
    }
  }
`
export const GET_STOCKTAKES = gql`
  {
    stocktakes {
      id
      StockTakeDate
      StockTakeID
      LocationID
      LocationName
      TransactionType
      Qty
      Bags
      FarmCode
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`

export const CREATE_STOCKTAKE = gql`
  mutation CreateStockTake(
    $TransactionType: TRANSACTION_TYPE
    $LocationID: ID
    $Stocks: [CreateStockInput!]!
  ) {
    createstocktake(
      TransactionType: $TransactionType
      LocationID: $LocationID
      Stocks: $Stocks
    ) {
      id
      StockTakeDate
      LocationID
      LocationName
      TransactionType
      Qty
      Bags
      FarmCode
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`

export const CREATE_STOCK = gql`
  mutation CreateStock(
    $ProductID: ID
    $OrderID: ID
    $Title: String
    $Qty: Int
  ) {
    createstock(
      ProductID: $ProductID
      OrderID: $OrderID
      Title: $Title
      Qty: $Qty
    ) {
      id
      ProductID
      ProductName
      OrderID
      Title
      Movement
      FarmCode
      Qty
      Bags
      KGToBags
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`

export const UPDATE_STOCK = gql`
  mutation UpdateStock(
    $id: ID
    $ProductID: ID
    $OrderID: ID
    $Title: String
    $Qty: Int
  ) {
    updatestock(
      id: $id
      ProductID: $ProductID
      OrderID: $OrderID
      Title: $Title
      Qty: $Qty
    ) {
      id
      ProductID
      ProductName
      OrderID
      Title
      Movement
      FarmCode
      Qty
      Bags
      KGToBags
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`
/* 
export const REMOVE_DRIVER = gql`
  mutation RemoveDriver($id: ID!) {
    removedriver(id: $id)
  }
`

export const UPDATE_DRIVER = gql`
  mutation UpdateDriver(
    $id: ID!
    $DriverNo: String
    $DriverName: String
    $ContactNo: String
    $Password: String
  ) {
    updatedriver(
      id: $id
      DriverName: $DriverName
      DriverNo: $DriverNo
      ContactNo: $ContactNo
      Password: $Password
    ) {
      id
      ProductID
      ProductName
      OrderID
      Title
      Movement
      Qty
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`

export const CREATE_STOCK = gql`
  mutation CreateStock(
    $ProductID: ID, $OrderID: ID, $Title: String, $Qty: Int
  ) {
    createstock(
      ProductID: $ProductID, OrderID: $OrderID, Title: $Title, Qty: $Qty
    ) {
      id
      ProductID
      ProductName
      OrderID
      Title
      Movement
      Qty
      CreatedBy
      CreatedOn
      ModifiedBy
      ModifiedOn
    }
  }
`
 */
