import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { Slider } from '@material-ui/core'
import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'
import Cropper from 'react-easy-crop'
import ImgDialog from './imgDialog'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

// const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT
const restApi = process.env.REACT_APP_API

const useStyles = makeStyles((theme) => ({
  dropZone: {
    height: 400,
    background: theme.palette.primary.paper,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    paddingTop: 10,
    paddingTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    paddingRight: 30,
  },
  sliderLabel: {
    paddingRight: 20,
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}))

// START
export default function ShopPaymentUploadDialog(props) {
  const classes = useStyles()
  const [file, setFile] = useState()
  const [disable, setDisable] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  /* const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )*/

  const [dbhost, setDbhost] = useState('spa2')
  const [imageFile, setImageFile] = useState()
  const [imageRef, setImageRef] = useState()
  const [crop, setCrop] = useState({
    unit: '%',
    width: 90,
    aspect: 1 / 1,
  })
  const [croppedImageUrl, setCroppedImageUrl] = useState()

  const handleUpload = async () => {
    try {
      const croppedImage = await getCroppedBlob(imageRef, crop, 'newFile.jpeg')
      const formData = new FormData()
      formData.append('uploads[]', croppedImage, 'tng.png')

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }

      axios
        .put(restApi + `/upload/touchngo`, formData, config)
        .then((response) => {
          //setCroppedImage(croppedImage)
          props.setOpen(false)
          props.refetch()
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (e) {
      console.error(e)
    }
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image) => {
    setImageRef(image)
  }

  const onCropComplete = (c) => {
    makeClientCrop(c)
  }

  const makeClientCrop = async () => {
    console.log('crop', crop)
    if (imageRef && crop.width && crop.height) {
      const file = await getCroppedImg(imageRef, crop, 'newFile.jpeg')
      setCroppedImageUrl(file)
    }
  }

  const getCroppedBlob = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    let originWidth = crop.width * scaleX
    let originHeight = crop.height * scaleY
    // maximum width/height
    let maxWidth = 1200,
      maxHeight = 1200 / (16 / 9)
    let targetWidth = originWidth,
      targetHeight = originHeight
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth
        targetHeight = Math.round(maxWidth * (originHeight / originWidth))
      } else {
        targetHeight = maxHeight
        targetWidth = Math.round(maxHeight * (originWidth / originHeight))
      }
    }
    let fileUrl
    // set canvas size
    canvas.width = targetWidth
    canvas.height = targetHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          blob.name = fileName
          resolve(blob)
        },
        'image/png',
        1
      )
    })
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    let originWidth = crop.width * scaleX
    let originHeight = crop.height * scaleY
    // maximum width/height
    let maxWidth = 1200,
      maxHeight = 1200 / (16 / 9)
    let targetWidth = originWidth,
      targetHeight = originHeight
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth
        targetHeight = Math.round(maxWidth * (originHeight / originWidth))
      } else {
        targetHeight = maxHeight
        targetWidth = Math.round(maxHeight * (originWidth / originHeight))
      }
    }
    let fileUrl
    // set canvas size
    canvas.width = targetWidth
    canvas.height = targetHeight
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error('Canvas is empty')
            return
          }
          blob.name = fileName
          window.URL.revokeObjectURL(fileUrl)
          fileUrl = window.URL.createObjectURL(blob)
          resolve(fileUrl)
        },
        'image/png',
        1
      )
    })
  }

  const onCropChange = (c, percentCrop) => {
    setCrop({ ...c })
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setImageFile(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="manifesting-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            Touch N Go
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {!imageFile && (
              <input
                type="file"
                name="myImage"
                onChange={onSelectFile}
                multiple
              />
            )}
            {imageFile && (
              <ReactCrop
                src={imageFile}
                crop={crop}
                ruleOfThirds
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
            {croppedImageUrl && (
              <img
                crossOrigin="anonymous"
                alt="Crop"
                style={{ maxWidth: '100%' }}
                src={croppedImageUrl}
              />
            )}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                handleUpload()
              }}
            >
              Upload
            </Button>
            {/* <Button
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => {
                generateDownload()
              }}
            >
              Upload2
            </Button> */}
          </DialogActions>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
